<template>
  <div>
    <modal-detalles-convocatoria :data-convocatoria="dataConvocatoria" />
    <modal-archivo-resultado @actualizarDataConvocatoria="actualizarDataConvocatoria" />
    <modal-formulario-postulacion
      :id-formulario="idFormulario"
      :id-usuario="idUsuario"
    />

    <b-row>
      <b-col
        xl="8"
        lg="8"
        md="8"
        sm="12"
        xs="12"
      >
        <b-card
          class="m-0 py-0"
          header-tag="header"
          header-class="bg-light-primary"
        >
          <template #header>
            <div class="d-flex justify-content-between ">
              <span>
                <h4 class="text-primary">
                  <strong>
                    <feather-icon
                      size="24"
                      icon="FeatherIcon"
                    />
                    {{ dataConvocatoria.nombre }}
                  </strong>
                  <b-badge
                    pill
                    :variant="`light-${resolveEstadoVariant(dataConvocatoria.estado)}`"
                    class="text-capitalize px-1 ml-1 mb-0"
                  >
                    {{ dataConvocatoria.estado }}
                  </b-badge>
                </h4>
              </span>
              <span class="ml-4">
                <small class="text-secondary"> Total postulantes: <span><span
                  class="text-center text-primary"
                  style="font-size:20px;"
                >{{ totalRows }}</span></span> </small>
              </span>
            </div>
          </template>
          <b-row
            class="m-0 p-0"
          >
            <b-col
              lg="12"
              xl="12"
              md="12"
              sm="12"
              xs="12"
              class="m-0 p-0"
            >
              <div class="mt-1">

                <li
                  v-for="(val,index) in dataConvocatoria.serviciosx"
                  :key="index"
                  style="margin-bottom:6px; display:inline;"
                  class="mr-3"
                >
                  <span class="justify-content-center">
                    <feather-icon
                      :icon="resolveServicioIcon(val.codigo)"
                      size="14"
                      class=""
                      :class="`text-${resolveServicioVariant(val.codigo)}`"
                    />
                  </span>
                  <span style="font-size:14px"> {{ val.nombre }} </span>
                  ( <small>N° Becas:</small> <strong>{{ val.n_becas }}</strong> )
                </li>

              </div>
            </b-col>

          </b-row>

        </b-card>
      </b-col>
      <b-col
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
      >
        <b-card class="border-primary mb-1">
          <b-button
            block
            size="sm"
            variant="outline-success"
            @click="$router.push({ name: 'descargar-resultado-PDF-convocatoria', params:{idConvocatoria : $router.currentRoute.params.id}})"
          >
            <feather-icon
              icon="FileTextIcon"
              size="15"
            />
            Resultados <span v-if="dataConvocatoria.estado !== 'concluido'">(previa)</span>
          </b-button>

          <b-button
            block
            style="margin-bottom: 7px"
            size="sm"
            :disabled="dataConvocatoria.estado == 'en curso' ? false : true"
            :variant="dataConvocatoria.estado == 'en curso' ? 'outline-success' :'outline-secondary'"
            @click="abrirModalArchivo"
          >
            <feather-icon
              icon="CheckSquareIcon"
              size="15"
            />
            Cargar archivo resultado
          </b-button>
          <b-button
            block
            style="margin-bottom: 7px"
            :variant="dataConvocatoria.archivo_resultado==null || dataConvocatoria.archivo_resultado=='' || dataConvocatoria.estado == 'concluido' || dataConvocatoria.estado == 'cancelado' ? 'outline-secondary' :'outline-success'"
            size="sm"
            :disabled="dataConvocatoria.archivo_resultado==null || dataConvocatoria.archivo_resultado=='' || dataConvocatoria.estado == 'concluido' || dataConvocatoria.estado == 'cancelado' ? true : false"
            @click="concluirConvocatoria"
          >
            <feather-icon
              icon="CheckSquareIcon"
              size="15"
            />
            Concluir convocatoria
          </b-button>

        </b-card>
      </b-col>
    </b-row>

    <postulantes-por-evaluar
      @actualizarTablaPostulantesEvaluados="actualizarTablaPostulantesEvaluados"
      @abrirModalFormulario="abrirModalFormulario"
      @asignarCantPostulantesPorEvaluar="(val) => cantPostulantesPorEvaluar = val"
    />
    <postulantes-evaluados
      :estado-convocatoria="dataConvocatoria.estado"
      @actualizarTablaPostulantesPorEvaluar="actualizarTablaPostulantesPorEvaluar"
      @abrirModalFormulario="abrirModalFormulario"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge,
  BButton,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import postulantesStoreModule from './postulantesStoreModule'
import ModalDetallesConvocatoria from './ModalDetallesConvocatoria.vue'
import ModalFormularioPostulacion from './ModalFormularioPostulacion.vue'
import ModalArchivoResultado from './ModalArchivoResultado.vue'
import PostulantesEvaluados from './PostulantesEvaluados.vue'
import PostulantesPorEvaluar from './PostulantesPorEvaluar.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    // BMedia,
    // BAvatar,
    // // BCardText,
    // BMediaAside,
    // BMediaBody,
    BBadge,
    // externo
    ModalDetallesConvocatoria,
    ModalFormularioPostulacion,
    PostulantesEvaluados,
    PostulantesPorEvaluar,
    ModalArchivoResultado,

  },
  data() {
    return {
      dataConvocatoria: {},
      idFormulario: 0,
      idUsuario: 0,
      totalRows: 0,
      cantPostulantesPorEvaluar: 0,
      host: process.env.VUE_APP_HOST,
    }
  },
  created() {
    store.dispatch('postulantes-store-module/getConvocatoria', this.$router.currentRoute.params.id).then(res => {
      // si el estado de la convocatoria es diferente de VIGENTE enviar a la lista de convocatorias
      if (res.data.estado === 'borrador') {
        this.$router.push({ name: 'social-convocatorias-list' })
        return
      }
      this.dataConvocatoria = res.data
      this.obtenerTotalPostulantes()
    }).catch(error => {
      if (error.response.status === 404) {
        this.$router.push({ name: 'social-convocatorias-list' })
      }
    })
  },
  methods: {
    actualizarDataConvocatoria() {
      store.dispatch('postulantes-store-module/getConvocatoria', this.$router.currentRoute.params.id).then(res => {
        this.dataConvocatoria = res.data
      })
    },
    obtenerTotalPostulantes() {
      this.$http.get(`/servicio-social/beca/convocatoria/${this.$router.currentRoute.params.id}/postulante`)
        .then(res => {
          this.totalRows = res.data.total
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Error en actualizar convocatoria!',
              icon: 'BellIcon',
              text: 'Se recomienda recargar la página',
              variant: 'danger',
            },
          })
        })
    },
    actualizarTablaPostulantesPorEvaluar() {
      this.$emit('actualizarTablaPostulantesPorEvaluar')
    },
    actualizarTablaPostulantesEvaluados() {
      this.$emit('actualizarTablaPostulantesEvaluados')
    },
    abrirModalFormulario(data) {
      this.idUsuario = parseInt(data.usuarioId, 10)
      this.idFormulario = parseInt(data.formularioId, 10)
      this.$bvModal.show('modal_formulario_postulacion')
    },
    abrirModalArchivo() {
      if (this.dataConvocatoria.estado === 'concluido' || this.dataConvocatoria.estado === 'cancelado') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Error!',
            icon: 'BellIcon',
            text: 'Convocatoria CONCLUIDA o CANCELADA',
            variant: 'danger',
          },
        })
        return
      }
      if (this.dataConvocatoria.estado !== 'en curso') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Error!',
            icon: 'BellIcon',
            text: 'La convocatoria no esta EN CURSO',
            variant: 'danger',
          },
        })
        return
      }
      this.$emit('actualizarArchivoResultado')
      this.$bvModal.show('modal_archivo_resultado')
    },
    concluirConvocatoria() {
      if (this.cantPostulantesPorEvaluar > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: `No puede concluir la convocatoria ya que tiene ${this.cantPostulantesPorEvaluar} postulante(s) sin evaluar`,
            variant: 'warning',
          },
        })
        return
      }

      this.$swal({
        title: '¿Estas seguro de concluir la convocatoria?',
        text: '¡No podrá revertir esta acción!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, concluir!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = {
            idConvocatoria: this.$router.currentRoute.params.id,
            dataEstado: { estado: 'concluido' },
          }
          store.dispatch('postulantes-store-module/actualizarEstadoConvocatoria', data).then(() => {
            const data2 = { servicios_id: this.dataConvocatoria.serviciosx.map(val => val.id) }
            store.dispatch('postulantes-store-module/migrarActualizarListaBecarios', data2).then(() => {
              this.$swal({
                icon: 'success',
                title: 'Éxito!',
                text: '¡La convocatoria ha concluido con éxito!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.$router.push({ name: 'social-convocatorias-list' })
              })
            })
          }).catch(error => {
            if (error.response.status >= 400 && error.response.status <= 499) {
              error.response.data.error.archivo_resultado.forEach(value => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: '¡Advertencia!',
                    text: value,
                    icon: 'XOctagonIcon',
                    variant: 'warning',
                  },
                })
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Falló la acción, error 500',
                  icon: 'XOctagonIcon',
                  variant: 'danger',
                },
              })
            }
          })
        }
      })
    },

  },
  setup() {
    const POSTULANTES_APP_STORE_MODULE_NAME = 'postulantes-store-module'

    // Register module
    if (!store.hasModule(POSTULANTES_APP_STORE_MODULE_NAME)) store.registerModule(POSTULANTES_APP_STORE_MODULE_NAME, postulantesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(POSTULANTES_APP_STORE_MODULE_NAME)) store.unregisterModule(POSTULANTES_APP_STORE_MODULE_NAME)
    })
    const resolveServicioVariant = value => {
      if (value === 'BECA-COMEDOR') return 'danger'
      if (value === 'BECA-RESIDENCIA') return 'success'
      if (value === 'BECA-BOLSA') return 'info'
      return 'primary'
    }
    const resolveServicioIcon = value => {
      if (value === 'BECA-COMEDOR') return 'CoffeeIcon'
      if (value === 'BECA-RESIDENCIA') return 'HomeIcon'
      if (value === 'BECA-BOLSA') return 'BriefcaseIcon'

      return 'UserIcon'
    }
    const resolveEstadoVariant = status => {
      if (status === 'borrador') return 'primary'
      if (status === 'vigente') return 'success'
      if (status === 'en curso') return 'warning'
      if (status === 'concluido') return 'danger'
      if (status === 'cancelado') return 'danger'
      return 'primary'
    }

    return {
      postulantesStoreModule,
      resolveServicioVariant,
      resolveServicioIcon,
      resolveEstadoVariant,
    }
  },
}
</script>
<style lang="scss">
</style>
