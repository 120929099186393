<template>

  <b-modal
    id="modal_asistenta_social_opinion1"
    title="Opinión Asistenta Social"
    size="md"
    ok-only
    :hide-footer="true"
  >
    <b-card
      no-body
      class="border mb-1 pr-1 mt-0"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            class="text-primary"
            icon="UserIcon"
            size="16"
          />
          <span
            class="text-primary align-middle ml-50"
            style="font-size:14px"
          >Postulante: </span> <span style="font-size:14px">{{ postulante.nombreCompleto }}</span>
          <br>
          <feather-icon
            class="text-primary"
            icon="FileTextIcon"
            size="16"
          />
          <span
            class="text-primary align-middle ml-50"
            style="font-size:14px"
          >Opinión postulante: </span>
          <span
            v-if="!postulante.opinion"
            class="text-danger ml-2"
            style="font-size:13px"
          > (Aún no se ha registrado la opinión por parte de la Asistenta Social)</span>
        </b-card-title>
      </b-card-header>

      <b-card-body>

        <div class="border p-2">
          <label for="">
            {{ postulante.opinion }}
          </label>
        </div>

      </b-card-body>

    </b-card>
    <b-button
      variant="outline-secondary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$bvModal.hide('modal_asistenta_social_opinion1')"
    >
      Cerrar
    </b-button>
  </b-modal>

</template>

<script>
import {
  BCard, BButton, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BCardHeader,
    BCardTitle,
    BCardBody,

  },

  data() {
    return {
      postulante: { nombreCompleto: '', opinion: '' },
    }
  },
  created() {
    this.$parent.$on('verOpinionPostulante', this.verOpinionPostulante)
  },
  methods: {
    verOpinionPostulante(dataPostulante) {
      this.postulante = dataPostulante
      this.$bvModal.show('modal_asistenta_social_opinion1')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
