import { render, staticRenderFns } from "./ModalAsistentaSocialOpinion.vue?vue&type=template&id=35f24919&scoped=true&"
import script from "./ModalAsistentaSocialOpinion.vue?vue&type=script&lang=js&"
export * from "./ModalAsistentaSocialOpinion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f24919",
  null
  
)

export default component.exports