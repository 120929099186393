<template>
  <b-modal
    id="modal_detalles_convocatoria"
    ok-only
    size="lg"
    class="p-5"
    header-bg-variant="info"
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          Detalles de la convocatoria
        </strong></div>

    </template>
    <pre>{{ dataConvocatoria }}</pre>
    <template #modal-footer>
      <span />
    </template>
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  props: {
    dataConvocatoria: {
      type: Object,
      default: () => {},
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
