<template>
  <div>
    <ModalAsistentaSocialOpinion2 />
    <b-card class="mt-1 px-2">
      <b-row v-show="cantPostulantes == 0">
        <b-col cols="12">
          <span class="text-success"
            ><strong>POSTULANTES EVALUADOS ( {{ cantPostulantes }} )</strong>
          </span>
        </b-col>
      </b-row>
      <div v-show="cantPostulantes !== 0">
        <b-table
          id="my-table"
          ref="refTablaPostulantesEvaluados"
          class="position-relative"
          show-empty
          head-variant="light"
          hover
          small
          outlined
          responsive
          :busy.sync="isBusy"
          :items="myProvider"
          :fields="fields"
          :no-provider-filtering="true"
          :current-page="currentPage"
          :empty-text="'No hay registros para mostrar'"
          :empty-filtered-text="
            'No hay registros que coincidan con su solicitud.'
          "
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :tbody-tr-class="['p-2']"
          caption-top
        >
          <template #table-caption>
            <b-row>
              <b-col cols="4">
                <span class="text-success"
                  ><strong
                    >POSTULANTES EVALUADOS ( {{ cantPostulantes }} )</strong
                  >
                </span>
              </b-col>
              <b-col cols="8" class="d-flex justify-content-end">
                <div class="mr-1">
                  <label>Mostrar</label>
                  <b-form-select
                    v-model="perPage"
                    :options="pageOptions"
                    :clearable="false"
                    size="sm"
                    style="width:auto"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>registros</label>
                </div>
                <div>
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Buscar..."
                    size="sm"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong> cargando...</strong>
            </div>
          </template>

          <template #cell(formularios)="row" class="py-2">
            <div
              v-for="(val, index) in dataConvocatoria.requisitosx"
              :key="index"
              class="text-center"
            >
              <!-- {{ dataConvocatoria.requisitosx }} -->
              <b-button
                style="margin-bottom:3px;"
                size="sm"
                variant="outline-primary"
                @click="
                  abrirModalFormulario(
                    row.item.usuario_id,
                    val.formulario_espejo_id
                  )
                "
              >
                <span style="font-size:10px">
                  {{ val.nombre }}
                </span>
              </b-button>
              <br />
            </div>
            <div class="text-center">
              <b-button
                class="mx-auto"
                size="sm"
                :variant="
                  row.item.opinion_ficha_social !== null &&
                  row.item.opinion_ficha_social !== ''
                    ? 'outline-primary'
                    : 'outline-danger'
                "
                @click="
                  verOpinionAsistentaSocial(
                    row.item.persona.nombre_completo,
                    row.item.opinion_ficha_social
                  )
                "
              >
                <span style="font-size:11px">
                  Opinión asistenta social
                </span>
              </b-button>
            </div>
          </template>

          <!-- Column: Puntaje -->
          <template #cell(evaluar)="row">
            <b-button
              size="sm"
              variant="outline-danger"
              :disabled="
                estadoConvocatoria == 'concluido' ||
                estadoConvocatoria == 'cancelado'
                  ? true
                  : false
              "
              @click="actualizarPostulanteEvaluado(row.item.id)"
            >
              mover a no evaluados
            </b-button>
          </template>

          <template #cell(postulante)="row">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :variant="`light-primary`"
                  :text="
                    avatarText(recorteName(row.item.persona.nombre_completo))
                  "
                />
              </template>
              <b-link class="font-weight-bold d-block text-nowrap">
                {{ row.item.persona.nombre_completo }}
              </b-link>
              <small
                ><strong>ETNIA:</strong>
                {{
                  row.item.persona.pueblo_indigena &&
                    row.item.persona.pueblo_indigena.nombre
                }}</small
              >
              <br />
              <small><strong>EP:</strong> {{ row.item.escuela.nombre }}</small>
            </b-media>
          </template>

          <template #cell(nota_promedio)="row">
            <div class="text-left">
              <small><strong>C. Aprobad.:</strong> </small>

              {{ row.item.nota ? row.item.nota.resumen.cursos_aprobados : 0 }}
              <br />
              <small><strong>C. Desaprob.:</strong></small>

              {{
                row.item.nota ? row.item.nota.resumen.cursos_no_aprobados : 0
              }}
              <br />
              <small><strong>PROMEDIO:</strong></small>

              {{ row.item.nota ? row.item.nota.promedio_ponderado : 0 }}
            </div>
          </template>

          <!-- Column: servicios postulante-->
          <template #cell(servicios_postulante)="row">
            <div
              v-for="(xx, index) in row.item.servicios"
              :key="index"
              class="text-nowrap"
            >
              <b-row v-if="servicio && servicio.detalles">
                <b-col cols="8" class="d-flex align-items-center">
                  <span
                    class="text-align-left"
                    :class="`text-${resolveServicioVariant(xx.codigo)}`"
                  >
                    <feather-icon
                      :icon="resolveServicioIcon(xx.codigo)"
                      size="15"
                      class="mr-50"
                      :class="`text-${resolveServicioVariant(xx.codigo)}`"
                    />
                    <span style="font-size: 14px">{{ xx.nombre }} </span></span
                  >
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-form-input
                    v-model="
                      postulantes[row.index].servicios[index].detalles[
                        'puntaje'
                      ]
                    "
                    style="width:50px"
                    size="sm"
                    type="number"
                    disabled
                  />
                  <feather-icon
                    :icon="
                      postulantes[row.index].servicios[index].detalles[
                        'puntaje'
                      ] > 0
                        ? 'CheckIcon'
                        : 'XIcon'
                    "
                    :class="
                      postulantes[row.index].servicios[index].detalles[
                        'puntaje'
                      ] > 0
                        ? 'text-success'
                        : 'text-danger'
                    "
                    size="18"
                    style="margin-left:2px"
                  />
                </b-col>
              </b-row>
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Mostrando registros del {{ from }} al {{ to }} de un total de
                {{ totalRows }} registros</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BSpinner,
  BRow,
  BCol,
  BPagination,
  BFormInput,
  BButton,
  BMedia,
  BAvatar,
  BLink,
  BFormSelect,
} from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import postulantesStoreModule from "./postulantesStoreModule";
import ModalAsistentaSocialOpinion2 from "./ModalAsistentaSocialOpinion2.vue";

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BFormSelect,
    ModalAsistentaSocialOpinion2,
  },
  props: {
    estadoConvocatoria: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [3, 25, 50, 100, 500],
      sortBy: "id",
      sortDesc: false,
      filter: "",
      filterOn: [],
      dataConvocatoria: {},
      dataPostulante: {},
      timeout: null,
      postulantes: {},
      cantPostulantes: 0,
    };
  },
  created() {
    this.$parent.$on(
      "actualizarTablaPostulantesEvaluados",
      this.actualizarTablaPostulantesEvaluados
    );
    store
      .dispatch(
        "postulantes-evaluados/getConvocatoria",
        this.$router.currentRoute.params.id
      )
      .then((res) => {
        this.dataConvocatoria = res.data;
      });
  },
  methods: {
    actualizarTablaPostulantesEvaluados() {
      this.filter = null;
      this.$refs.refTablaPostulantesEvaluados.refresh();
    },
    verOpinionAsistentaSocial(nombreCompleto, opinion) {
      const data = { nombreCompleto, opinion };

      this.$emit("verOpinionPostulante2", data);
    },
    async myProvider(ctx) {
      const promise = this.$http.get(
        `/servicio-social/beca/convocatoria/${this.$router.currentRoute.params.id}/postulante`,
        { params: ctx }
      );

      return promise.then((response) => {
        const { total, from, to } = response.data;
        let { data } = response.data;
        this.postulantes = response.data.data;

        data = data.filter((postulante) => postulante.evaluado === 1);
        this.cantPostulantes = data.length;

        const items = data;
        this.totalRows = total || 0;

        this.from = from || 0;
        this.to = to || 0;

        return items || [];
      });
    },

    recorteName(name) {
      let recorte = [];
      recorte = name.split(" ");
      return `${recorte[0]} ${recorte[1]}`;
    },

    abrirModalFormulario(usuarioId, formularioId) {
      this.$emit("abrirModalFormulario", { usuarioId, formularioId });
    },

    actualizarPostulanteEvaluado(idPostulante) {
      store
        .dispatch(
          "postulantes-evaluados/actualizarEvaluadoDePostulante",
          idPostulante
        )
        .then(() => {
          this.actualizarTablaPostulantesEvaluados();
          this.$emit("actualizarTablaPostulantesPorEvaluar");
        });
    },
    asignarPuntajeServicio(value, dataServicio, dataPostulante) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const data = [
          {
            puntaje: value,
            servicio_id: dataServicio.id,
          },
        ];
        store.dispatch("postulantes-evaluados/actualizarPuntaje", {
          idConvocatoria: dataPostulante.convocatoria_id,
          idPostulante: dataPostulante.id,
          data,
        });
      }, 500);
    },
  },
  setup() {
    const POSTULANTES_APP_STORE_MODULE_NAME = "postulantes-evaluados";

    // Register module
    if (!store.hasModule(POSTULANTES_APP_STORE_MODULE_NAME))
      store.registerModule(
        POSTULANTES_APP_STORE_MODULE_NAME,
        postulantesStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(POSTULANTES_APP_STORE_MODULE_NAME))
        store.unregisterModule(POSTULANTES_APP_STORE_MODULE_NAME);
    });

    const fields = [
      { key: "postulante", label: "POSTULANTE", sortable: true },
      { key: "formularios", label: "Requisitos", sortable: true },
      { key: "nota_promedio", label: "PROM. PONDERADO", sortable: true },
      {
        key: "servicios_postulante",
        label: "EVALUACIÓN",
        class: "colorEvaluacion py-1",
        sortable: true,
      },
      {
        key: "evaluar",
        label: "EVALUAR",
        class: "text-center",
        sortable: true,
      },
    ];

    const resolveServicioVariant = (value) => {
      if (value === "BECA-COMEDOR") return "primary";
      if (value === "BECA-RESIDENCIA") return "primary";
      if (value === "BECA-BOLSA") return "primary";
      return "primary";
    };
    const resolveServicioIcon = (value) => {
      if (value === "BECA-COMEDOR") return "CoffeeIcon";
      if (value === "BECA-RESIDENCIA") return "HomeIcon";
      if (value === "BECA-BOLSA") return "BriefcaseIcon";

      return "UserIcon";
    };

    return {
      // Filter
      avatarText,
      fields,
      postulantesStoreModule,
      resolveServicioVariant,
      resolveServicioIcon,
    };
  },
};
</script>
<style lang="scss">
.colorEvaluacion {
  background-color: rgb(255, 255, 255);
  padding: 20px 0px;
}
</style>
