<template>
  <div>
    <span
      v-for="(pregunta, index) in preguntas"
      :key="index"
    >
      <b-card
        v-if="pregunta.visible === 1"
        class="px-0 mb-1"
      >
        <div class="d-flex justify-content-between">
          <span class="text-default">
            {{ index+1 }}.
            <strong>{{ pregunta.pregunta }}</strong><br>
            <small>{{ pregunta.descripcion }}</small>
          </span>
          <div v-if="pregunta.tipo !== 'compuesta'">
            <small
              v-if="pregunta.requerido === 1"
              class="text-danger"
            >(*) Obligatorio</small>
            <small
              v-else
              class="text-info"
            >(opcional)</small>
          </div>
        </div>
        <br>

        <!-- respuesta_corta || parrafo -->
        <div v-if="pregunta.tipo ==='respuesta_corta' || pregunta.tipo ==='parrafo' || pregunta.tipo ==='fecha' || pregunta.tipo ==='hora'">

          <span v-if="pregunta.respuestax[indexCompuesta]">
            <feather-icon
              class="text-success"
              size="16"
              icon="CheckIcon"
            />
            {{ pregunta.respuestax[indexCompuesta].respuesta }}
          </span>
          <span
            v-else
            class="text-danger"
          >
            No hay respuesta
          </span>
        <!-- <pre>{{ respuestaCorta }}</pre> -->
        </div>

        <!-- varias_opciones -->
        <div
          v-else-if="pregunta.tipo === 'varias_opciones' || pregunta.tipo === 'desplegable'"
        >
          <span v-if="pregunta.respuestax[indexCompuesta]">
            <feather-icon
              class="text-success"
              size="16"
              icon="CheckIcon"
            />
            {{ pregunta.detalles.find(val => val.id === parseInt(pregunta.respuestax[indexCompuesta].respuesta, 10)).respuesta }}
          </span>
          <span
            v-else
            class="text-danger"
          >
            No hay respuesta
          </span>
        <!-- <pre>{{ variasOpciones[pregunta.id] }}</pre> -->
        </div>

        <!-- mixto_varias_opciones -->
        <div v-else-if="pregunta.tipo === 'mixto_varias_opciones' || pregunta.tipo === 'mixto_desplegable'">
          <span v-if=" pregunta.respuestax[indexCompuesta]">
            <feather-icon
              class="text-success"
              size="16"
              icon="CheckIcon"
            />
            {{ pregunta.detalles.find(val => val.id === parseInt(pregunta.respuestax[indexCompuesta].respuesta, 10)).respuesta }}
            <span v-if="pregunta.detalles.find(val => val.id === parseInt(pregunta.respuestax[indexCompuesta].respuesta, 10)).mixto">
              <br>
              <div>
                <span class="text-danger ml-2">{{ pregunta.detalles.find(val => val.id === parseInt(pregunta.respuestax[indexCompuesta].respuesta, 10)).mixto_detalles }}</span> : Rpta. {{ pregunta.respuestax[indexCompuesta].respuesta_mixto }}
              </div>
            </span>
          </span>
          <span
            v-else
            class="text-danger"
          >
            No hay respuesta
          </span>
        </div>

        <!-- casillas -->
        <div v-else-if="pregunta.tipo === 'casillas' || pregunta.tipo === 'mixto_casillas'">

          <span v-if="pregunta.respuestax[indexCompuesta]">

            <span
              v-for="(data, index2) in pregunta.respuestax[indexCompuesta].respuesta.split('[~]')"
              :key="index2"
            >
              <div
                v-if="pregunta.detalles.find(val => val.id === parseInt(data, 10))"
                class="mt-1"
              >
                <feather-icon
                  class="text-success"
                  size="16"
                  icon="CheckIcon"
                /> {{ pregunta.detalles.find(val => val.id === parseInt(data, 10)).respuesta }}
                <span v-if="pregunta.detalles.find(val => val.id === parseInt(data, 10)).mixto">
                  <br>
                  <div>
                    <span class="text-danger ml-2">{{ pregunta.detalles.find(val => val.id === parseInt(data, 10)).mixto_detalles }}</span> : Rpta. {{ pregunta.respuestax[indexCompuesta].respuesta_mixto.split('[~]')[index2] }}
                  </div>
                </span>
              </div>
            </span>
          </span>
          <span
            v-else
            class="text-danger"
          >
            No hay respuesta
          </span>
        </div>

        <!-- archivo -->
        <div v-else-if="pregunta.tipo === 'archivo'">
          <feather-icon
            class="text-success"
            size="16"
            icon="CheckIcon"
          />
          <a
            v-if="pregunta.respuestax[indexCompuesta]"
            :href="pregunta.respuestax[indexCompuesta].respuesta"
            class="btn btn-sm btn-outline-success ml-1"
            target="_blank"
          >ver archivo
          </a>
          <span
            v-else
            class="text-danger"
          >
            No hay respuesta
          </span>
        <!-- <pre>{{ archivo }}</pre> -->
        </div>

        <div v-else>
          NO HAY PREGUNTA SELECCIONADA
        </div>
      </b-card>
    </span>

  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
// import { mapState } from 'vuex'
// import PreguntaCompuesta from '@/views/administrador/gestion-formulario/formulario/PreguntaCompuesta.vue'

export default {
  components: {
    BCard,
    // BRow,
    // BCol,
    // extern
  },
  props: {
    preguntas: {
      type: Array,
      default: () => [],
    },
    indexCompuesta: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mixto: '', // usado solo en mixto_deplegable
    }
  },

}
</script>
