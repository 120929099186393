<template>
  <div>
    <b-modal
      id="modal_archivo_resultado"
      title="Cargar archivo resultado"
      size="md"
      no-close-on-backdrop
      :hide-footer="true"
      no-close-on-esc
      hide-header-close
    >
      <b-card
        no-body
        class="border mb-1 pr-1 mt-0"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="FileTextIcon"
              size="16"
            />
            <span class="align-middle ml-50">Cargar documento</span>
          </b-card-title>
        </b-card-header>
        <b-card-body>

          <b-form
            enctype="multipart/form-data"
            @submit="formSubmit"
          >
            <b-row>
              <b-col
                lg="12"
                md="12"
                xs="12"
                class="mb-1"
              >

                <b-form-file
                  v-model="form.file"
                  size="sm"
                  :browse-text="'Seleccionar archivo'"
                  :placeholder="'No se eligió archivo'"
                  required
                  accept=".pdf"
                >
                  <template
                    slot="file-name"
                    slot-scope="{ names }"
                  >
                    <b-badge variant="primary">
                      {{ names[0] }}
                    </b-badge>
                  </template>
                </b-form-file>

              </b-col>

              <b-col
                lg="12"
                md="12"
                xs="12"
              >
                <b-button
                  variant="primary"
                  class=" px-1"
                  size="sm"
                  style="padding: 8px 10px"
                  block
                  type="submit"
                >
                  CARGAR ARCHIVO
                  <feather-icon
                    icon="SaveIcon"
                    size="14"
                    class="m-0 p-0"
                  />
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </b-card-body>
      </b-card>

      <!-- RESULTADO TABLE -->
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="Volume2Icon"
              size="16"
            />
            <span class="align-middle ml-50">Documento cargado</span>
          </b-card-title>
        </b-card-header>

        <b-table
          striped
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          :empty-text="'No hay registros para mostrar'"
          show-empty
          head-variant="dark"
          small
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Cargando...</strong>
            </div>
          </template>

          <template #cell(ver)="row">
            <b-button
              variant="outline-info"
              size="sm"
              :href="row.item.archivo"
              :target="'_blank'"
            >
              ver
              <feather-icon
                :id="`invoice-row-5-preview-icon`"
                icon="DownloadIcon"
                size="13"
              />
            </b-button>
          </template>
          <template #cell(eliminar)>
            <b-button
              size="sm"
              variant="outline-danger"
            >
              eliminar
              <feather-icon
                :id="`invoice-row-5-preview-icon`"
                icon="Trash2Icon"
                size="13"
              />
            </b-button>
          </template>
        </b-table>
      </b-card>
      <b-button
        variant="outline-secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$bvModal.hide('modal_archivo_resultado')"
      >
        Cerrar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
// import { ref } from '@vue/composition-api'
import {
  BTable, BCard, BRow, BCol, BButton, BBadge, BFormFile, BForm, BCardHeader, BCardTitle, BCardBody, BSpinner,
} from 'bootstrap-vue'
// import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import comunicadosStoreModule from './comunicadoStoreModule'

export default {
  components: {
    BCard, BRow, BCol, BTable, BBadge, BButton, BFormFile, BForm, BCardHeader, BCardTitle, BCardBody, BSpinner,
  },

  data() {
    return {
      isBusy: true,
      items: [],
      fields: [
        { key: 'ver', label: 'ver' },
        { key: 'eliminar', label: 'eliminar' },
      ],
      form: {
        file: null,
      },

    }
  },
  created() {
    this.$parent.$on('actualizarArchivoResultado', this.actualizarArchivoResultado)
  },
  methods: {
    actualizarArchivoResultado() {
      this.$http.get(`/servicio-social/beca/convocatoria/${this.$router.currentRoute.params.id}`).then(res => {
        this.items = []
        if (res.data.archivo_resultado !== null && res.data.archivo_resultado !== '') {
          this.items.push({
            archivo: res.data.archivo_resultado,
          })
        }

        this.isBusy = false
      })
    },

    formSubmit(e) {
      e.preventDefault()

      const data = new FormData()
      data.append('archivo_resultado', this.form.file)
      this.$http.post(`/servicio-social/beca/convocatoria/${this.$router.currentRoute.params.id}/archivo-resultado`, data)
        .then(() => {
          this.actualizarArchivoResultado()
          this.$emit('actualizarDataConvocatoria')
          this.form.file = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Archivo cargado correctamente!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            error.response.data.error.archivo_resultado.forEach(value => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: '¡Advertencia!',
                  text: value,
                  icon: 'XOctagonIcon',
                  variant: 'warning',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Falló la carga, error 500',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
