var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.preguntas),function(pregunta,index){return _c('span',{key:index},[(pregunta.visible === 1)?_c('b-card',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"text-default"},[_vm._v(" "+_vm._s(index + 1)+". "),_c('strong',[_vm._v(_vm._s(pregunta.pregunta))]),_c('br'),_c('small',[_vm._v(_vm._s(pregunta.descripcion))])]),(pregunta.tipo !== 'compuesta')?_c('div',[(pregunta.requerido === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("(*) Obligatorio")]):_c('small',{staticClass:"text-info"},[_vm._v("(opcional)")])]):_vm._e()]),_c('br'),(
          pregunta.tipo === 'respuesta_corta' ||
            pregunta.tipo === 'parrafo' ||
            pregunta.tipo === 'fecha' ||
            pregunta.tipo === 'hora'
        )?_c('div',[(pregunta.respuestax)?_c('span',[_c('feather-icon',{staticClass:"text-success",attrs:{"size":"16","icon":"CheckIcon"}}),_vm._v(" "+_vm._s(pregunta.respuestax.respuesta)+" ")],1):_c('span',{staticClass:"text-danger"},[_vm._v(" No hay respuesta ")])]):(
          pregunta.tipo === 'varias_opciones' ||
            pregunta.tipo === 'desplegable'
        )?_c('div',[(pregunta.respuestax)?_c('span',[_c('feather-icon',{staticClass:"text-success",attrs:{"size":"16","icon":"CheckIcon"}}),_vm._v(" "+_vm._s(pregunta.detalles.find( function (val) { return val.id === parseInt(pregunta.respuestax.respuesta, 10); } ).respuesta)+" ")],1):_c('span',{staticClass:"text-danger"},[_vm._v(" No hay respuesta ")])]):(
          pregunta.tipo === 'mixto_varias_opciones' ||
            pregunta.tipo === 'mixto_desplegable'
        )?_c('div',[(pregunta.respuestax)?_c('span',[_c('feather-icon',{staticClass:"text-success",attrs:{"size":"16","icon":"CheckIcon"}}),_vm._v(" "+_vm._s(pregunta.detalles.find( function (val) { return val.id === parseInt(pregunta.respuestax.respuesta, 10); } ).respuesta)+" "),(
              pregunta.detalles.find(
                function (val) { return val.id === parseInt(pregunta.respuestax.respuesta, 10); }
              ).mixto
            )?_c('span',[_c('br'),_c('div',[_c('span',{staticClass:"text-danger ml-2"},[_vm._v(_vm._s(pregunta.detalles.find( function (val) { return val.id === parseInt(pregunta.respuestax.respuesta, 10); } ).mixto_detalles))]),_vm._v(" : Rpta. "+_vm._s(pregunta.respuestax.respuesta_mixto)+" ")])]):_vm._e()],1):_c('span',{staticClass:"text-danger"},[_vm._v(" No hay respuesta ")])]):(
          pregunta.tipo === 'casillas' || pregunta.tipo === 'mixto_casillas'
        )?_c('div',[(pregunta.respuestax && pregunta.respuestax.respuesta)?_c('span',_vm._l((pregunta.respuestax.respuesta.split(
              '[~]'
            )),function(data,index2){return _c('span',{key:index2},[(
                pregunta.detalles.find(function (val) { return val.id === parseInt(data, 10); })
              )?_c('div',{staticClass:"mt-1"},[_c('feather-icon',{staticClass:"text-success",attrs:{"size":"16","icon":"CheckIcon"}}),_vm._v(" "+_vm._s(pregunta.detalles.find(function (val) { return val.id === parseInt(data, 10); }) .respuesta)+" "),(pregunta.detalles.find(function (val) { return val.id == data; }).mixto)?_c('span',[_c('br'),_c('div',[_c('span',{staticClass:"text-danger ml-2"},[_vm._v(_vm._s(pregunta.detalles.find( function (val) { return val.id === parseInt(data, 10); } ).mixto_detalles))]),_vm._v(" : Rpta. "+_vm._s(pregunta.respuestax.respuesta_mixto.split("[~]")[index2])+" ")])]):_vm._e()],1):_vm._e()])}),0):_c('span',{staticClass:"text-danger"},[_vm._v(" No hay respuesta ")])]):(pregunta.tipo === 'archivo')?_c('div',[_c('feather-icon',{staticClass:"text-success",attrs:{"size":"16","icon":"CheckIcon"}}),(pregunta.respuestax)?_c('a',{staticClass:"btn btn-sm btn-outline-success ml-1",attrs:{"href":pregunta.respuestax.respuesta,"target":"_blank"}},[_vm._v("ver archivo ")]):_c('span',{staticClass:"text-danger"},[_vm._v(" No hay respuesta ")])],1):(pregunta.tipo === 'compuesta')?_c('div',_vm._l((pregunta.compuesta[0].respuestax),function(item,indexCompuesta){return _c('span',{key:indexCompuesta},[_c('strong',{staticClass:"text-primary"},[_vm._v("Respuesta :")]),_c('RespuestaCompuesta',{staticClass:"mt-1 p-1 border rounded",staticStyle:{"background":"#EFFBEF"},attrs:{"index-compuesta":parseInt(indexCompuesta, 10),"preguntas":pregunta.compuesta}}),_c('br')],1)}),0):_c('div',[_vm._v(" NO HAY PREGUNTA SELECCIONADA ")])]):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }