import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getConvocatoria(ctx, idConvocatoria) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/beca/convocatoria/${idConvocatoria}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    actualizarPuntaje(ctx, { idConvocatoria, idPostulante, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-social/beca/convocatoria/${idConvocatoria}/postulante/${idPostulante}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    actualizarEvaluadoDePostulante(ctx, idPostulante) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/beca/postulante/${idPostulante}/actualizar-evaluado`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    actualizarEstadoConvocatoria(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/beca/convocatoria/${data.idConvocatoria}/actualizar-estado`, data.dataEstado)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    migrarActualizarListaBecarios(ctx, servicios) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/beca/actualizar-becario', servicios)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    vistaPreviaResultados(ctx, idConvocatoria) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/servicio-social/beca/convocatoria/${idConvocatoria}/resultados`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    calcularResultados(ctx, idConvocatoria) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/beca/convocatoria/${idConvocatoria}/calcular-resultados`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
