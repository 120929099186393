<template>
  <div>

    <h3 class="text-center text-primary">
      <strong>{{ formulario.nombre }}</strong>
    </h3>

    <form-wizard
      next-button-text="Siguiente"
      back-button-text="Anterior"
      color="#06634c"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      :finish-button-text="'Fin'"
      class="wizard-horizontal"
      style="background:#F4F4F4"
    >
      <tab-content
        v-for="(seccion, index) in formulario.secciones"
        :key="index"
        class="px-0 py-0"
      >
        <b-card
          class="bg-light-primary m-0"
        >
          <span style="font-size:16px"><strong>{{ seccion.titulo }}</strong></span><br>
          <span
            class="ml-2"
            style="font-size:13px"
          >{{ seccion.subtitulo }}</span>
        </b-card>

        <respuestas
          class="mt-1"
          :preguntas="seccion.preguntas"
        />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>

import {
  BCard,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Respuestas from './Respuestas.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    Respuestas,
    BCard,

  },
  props: {
    idFormulario: {
      type: Number,
      default: 0,
    },
    idUsuario: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formulario: {},
    }
  },

  watch: {
    idUsuario() {
      this.cargarRespuesta()
    },
  },
  mounted() {
    this.cargarRespuesta()
  },

  methods: {

    cargarRespuesta() {
      // consultamos si el formulario es anonimo o regular

      this.$http.get(`/recursos/formulario/${this.idFormulario}/respuesta?usuario=${this.idUsuario}`).then(res => {
        this.formulario = res.data
      })
    },

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  </style>
<style scoped>
 .wizard-icon-container{
   background-color: red;
 }
</style>
