<template>
  <div>
    <b-modal
      id="modal_formulario_postulacion"
      ok-only
      size="lg"
      class="p-5"
      title="Formulario"

      modal-class="modal-success"
      :hide-footer="true"
    >
      <formulario-vista-lectura
        :id-formulario="idFormulario"
        :id-usuario="`${idUsuario}`"
      />
      <template #modal-footer>
        <div class="w-100" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import FormularioVistaLectura from '@/views/administrador/gestion-formulario/vista-lectura/FormularioVistaLectura.vue'

export default {
  components: {
    FormularioVistaLectura,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    idFormulario: {
      type: Number,
      default: 0,
    },
    idUsuario: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss">
</style>
